import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useState, useEffect } from "react"
import { useLocation } from "@reach/router"

const Panels = () => {
  const transition = { duration: 8, ease: [0.6, -0.05, 0.01, 0.9] }
  const [panelComplete, setPanelComplete] = useState(false)
  const [width, setWidth] = useState("")

  const path = useLocation().pathname

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", () => {})
    }
  }, [])

  return width > 1023 ? (
    <>
      <motion.div
        style={{ backgroundColor: panelComplete ? "#b63c11" : "#b63c11" }}
        className="left-panel-background"
        initial={{ height: 0 }}
        animate={{ height: [0, parseInt(width), 0], top: [0, 0, 0] }}
        exit={{
          height: [0, parseInt(width), 0],
          top: [0, 0, parseInt(width)],
        }}
        transition={{ ...transition, duration: 2, times: [0, 0.5, 1] }}
      ></motion.div>
      <motion.div
        style={{ backgroundColor: panelComplete ? "#b63c11" : "#b63c11" }}
        className="right-panel-background"
        initial={{ height: 0 }}
        animate={{
          height: [0, parseInt(width), 0],
          top: [0, 0, parseInt(width)],
        }}
        exit={{
          height: [0, parseInt(width), 0],
          top: [0, 0, parseInt(width)],
        }}
        transition={{ ...transition, duration: 2, times: [0, 0.5, 1] }}
        onAnimationComplete={() => {
          setPanelComplete(!panelComplete)
        }}
      ></motion.div>
    </>
  ) : (
    ""
  )
}

export default Panels
